<template>
    <div class="flex gap-2 w-full justify-between items-center">
        <span class="ml-3 font-semibold is-size-5">Menaxho rezervimet</span>
        <div class="border rounded p-1 w-1/3 text-14px">
            <input type="text"
                v-model="useReservationStore().search_val" 
                class="px-1"
                placeholder="Kerko rezervimet">
        </div>
        <div>
            <select class="border rounded" name="" id="">
                <!-- <option value="">? POS ?</option> -->
                <!-- <option value="">? warehouse ?</option> -->
            </select>
        </div>
    </div>
    <div class="mt-2">
        <!-- <pre> {{useReservationStore().reservation_list}} </pre> -->
        <table class="table is-bordered is-fullwidth mb-3 is-narrow">
            <thead style="background: #f0f0f0">
                <th>Produkti</th>
                <th>Klienti</th>
                <th>POS</th>
                <th>Data e fillimit</th>
                <th>Data e mbarimit</th>
                <th class="text-center" width="10px">#</th>
            </thead>
            <tbody>
                <tr v-for="(reservation, index) in useReservationStore().reservation_filtered" :key="index"
                :class="{'bg-green-50' : reservation.completed_status == 1}">
                    <td>{{reservation.product_name}}</td>
                    <td>{{reservation.customer_name}}</td>
                    <td>{{reservation.pos_name}}</td>
                    <td>{{reservation.created_at}}</td>
                    <td>{{reservation.requested_for}}</td>
                    <td>
                        <a v-if="reservation.completed_status == 0" @click.prevent="start_handover(reservation)" class="border rounded px-1" href="">
                            <i class="fa-solid fa-boxes-packing"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="pagination flex justify-center">
        <Dapaginator 
            :totalPages="useReservationStore().total_pages" 
            :currentPage="useReservationStore().current_page" 
            @changePage="useReservationStore().change_page"></Dapaginator>
    </div>
</template>

<script>
export default {

}
</script>

<script setup>
import { inject } from 'vue'
import {useReservationStore} from '@/stores/reservation'
import Dapaginator from '@/components/Dapaginator.vue'
const toast = inject("toast");

const start_handover = async (reservation) => {
    var a = await useReservationStore().handover(reservation)
    if(a.message == 'success'){
        toast.success('Rezervimi i perditesua me sukses.');
    }
}
</script>

<style>

</style>