<template> 
  <div class="flex justify-center items-center gap-2"> 
    <button 
      :disabled="currentPage === 1" 
      @click="changePage(currentPage - 1)" 
      class="px-2 py-1 rounded-md bg-gray-200 text-gray-500 hover:bg-gray-300 hover:text-gray-600 disabled:opacity-50 disabled:cursor-not-allowed" 
    > 
      <i class="fa fa-chevron-left"></i> 
    </button> 

    <div class="px-2 py-1 rounded-md bg-green-50 text-gray-500 hover:bg-gray-300 hover:text-gray-600"> 
      {{ currentPage }} 
    </div> 
    Of 
    <div class="px-2 py-1 rounded-md bg-gray-100 text-gray-500 hover:bg-gray-300 hover:text-gray-600"> 
      {{ totalPages }}
    </div>
    <button 
      :disabled="currentPage === totalPages" 
      @click="changePage(currentPage + 1)" 
      class="px-2 py-1 rounded-md bg-gray-200 text-gray-500 hover:bg-gray-300 hover:text-gray-600 disabled:opacity-50 disabled:cursor-not-allowed" 
    > 
      <i class="fa fa-chevron-right"></i> 
    </button>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits } from 'vue' 
const props = defineProps({ 
  currentPage: { 
    type: Number, 
    default: 1 
  }, 
  totalPages: { 
    type: Number, 
    default: 1 
  } 
})
const currentPage = ref(1)
const totalPages = ref(1)

const emit = defineEmits(['changePage'])

const changePage = (page) => {
  currentPage.value = page
  emit('changePage', page)
}
watch(() => props.totalPages, (total) => {
  totalPages.value = total
})

</script>
